import React, { FC, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import MetaTags from '../components/MetaTags';
import Header from '../components/Header';
import Footer from '../components/Footer';

import { theme } from '../styles/theme';

import { handleScroll } from '../helpers/scrollTop';
import { FaqElement } from '../sections/pricing/components/FaqElement';

const Layout = styled.section`
  display: flex;
  padding: 80px 10% 200px;
  min-height: 100vh;
  min-width: 100%;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 150px 50px 250px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};
`;

const PageTitle = styled.h2`
  font-weight: 900;
  font-size: 22px;
  line-height: 1.45;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
  }
`;

const Heading = styled.h2`
  margin-top: 32px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.45;
  text-transform: uppercase;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 28px;
  }
`;

const HeadingList = styled.h4`
  margin-top: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.45;
  text-transform: uppercase;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 20px;
  }
`;

const Paragraph = styled.p`
  margin-top: 32px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 300;

  span {
    display: block;
  }

  ol,
  ul {
    margin-left: 20px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 22px;
    text-align: justify;
  }
`;

const Privacy: FC = () => {
  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MetaTags title='Privacy Policy' />
      <Header />
      <Layout>
        <Container>
          <PageTitle>Privacy Policy of illume</PageTitle>
          <Paragraph>This Application collects some Personal Data from its Users.</Paragraph>
          <Paragraph>
            This document contains a section dedicated to California consumers and their privacy
            rights. <br />
            This document contains a section dedicated to Virginia consumers and their privacy
            rights. <br />
            This document can be printed for reference by using the print command in the settings of
            any browser.
          </Paragraph>
          <Heading>Owner and Data Controller</Heading>
          <Paragraph>
            Lovenotes Company dba illume <br />
            9800 Wilshire Blvd <br />
            Beverly Hills, CA 90212
            <br />
            <b>Owner contact email</b>: email@illumenotes.com
          </Paragraph>
          <Heading>Types of Data collected</Heading>
          <Paragraph>
            Among the types of Personal Data that this Application collects, by itself or through
            third parties, there are: first name; email address; Cookies; Usage Data; various types
            of Data; country; city; phone number; Phone permission; SMS permission; Storage
            permission; last name; date of birth.
          </Paragraph>
          <Paragraph>
            Complete details on each type of Personal Data collected are provided in the dedicated
            sections of this privacy policy or by specific explanation texts displayed prior to the
            Data collection. Personal Data may be freely provided by the User, or, in case of Usage
            Data, collected automatically when using this Application.
            <br />
            Unless specified otherwise, all Data requested by this Application is mandatory and
            failure to provide this Data may make it impossible for this Application to provide its
            services. In cases where this Application specifically states that some Data is not
            mandatory, Users are free not to communicate this Data without consequences to the
            availability or the functioning of the Service. <br />
            Users who are uncertain about which Personal Data is mandatory are welcome to contact
            the Owner. Any use of Cookies - or of other tracking tools - by this Application or by
            the owners of third-party services used by this Application serves the purpose of
            providing the Service required by the User, in addition to any other purposes described
            in the present document and in the Cookie Policy, if available.
          </Paragraph>
          <Paragraph>
            Users are responsible for any third-party Personal Data obtained, published or shared
            through this Application and confirm that they have the third party&apos;s consent to
            provide the Data to the Owner.
          </Paragraph>
          <Heading>Mode and place of processing the Data</Heading>
          <HeadingList>Methods of processing</HeadingList>
          <Paragraph>
            The Owner takes appropriate security measures to prevent unauthorized access,
            disclosure, modification, or unauthorized destruction of the Data.
            <br />
            The Data processing is carried out using computers and/or IT enabled tools, following
            organizational procedures and modes strictly related to the purposes indicated. In
            addition to the Owner, in some cases, the Data may be accessible to certain types of
            persons in charge, involved with the operation of this Application (administration,
            sales, marketing, legal, system administration) or external parties (such as third-party
            technical service providers, mail carriers, hosting providers, IT companies,
            communications agencies) appointed, if necessary, as Data Processors by the Owner. The
            updated list of these parties may be requested from the Owner at any time.
          </Paragraph>
          <HeadingList>Legal basis of processing</HeadingList>
          <Paragraph>
            The Owner may process Personal Data relating to Users if one of the following applies:
            <br />
            <ul>
              <li>
                Users have given their consent for one or more specific purposes. Note: Under some
                legislations the Owner may be allowed to process Personal Data until the User
                objects to such processing (“opt-out”), without having to rely on consent or any
                other of the following legal bases. This, however, does not apply, whenever the
                processing of Personal Data is subject to European data protection law;
              </li>
              <li>
                provision of Data is necessary for the performance of an agreement with the User
                and/or for any pre-contractual obligations thereof;
              </li>
              <li>
                processing is necessary for compliance with a legal obligation to which the Owner is
                subject;
              </li>
              <li>
                processing is related to a task that is carried out in the public interest or in the
                exercise of official authority vested in the Owner;
              </li>
              <li>
                processing is necessary for the purposes of the legitimate interests pursued by the
                Owner or by a third party.
              </li>
            </ul>
            <br />
            In any case, the Owner will gladly help to clarify the specific legal basis that applies
            to the processing, and in particular whether the provision of Personal Data is a
            statutory or contractual requirement, or a requirement necessary to enter into a
            contract.
          </Paragraph>
          <HeadingList>Place</HeadingList>
          <Paragraph>
            The Data is processed at the Owner&apos;s operating offices and in any other places
            where the parties involved in the processing are located.
          </Paragraph>
          <Paragraph>
            Depending on the User&apos;s location, data transfers may involve transferring the
            User&apos;s Data to a country other than their own. To find out more about the place of
            processing of such transferred Data, Users can check the section containing details
            about the processing of Personal Data.
          </Paragraph>
          <Paragraph>
            Users are also entitled to learn about the legal basis of Data transfers to a country
            outside the European Union or to any international organization governed by public
            international law or set up by two or more countries, such as the UN, and about the
            security measures taken by the Owner to safeguard their Data.
          </Paragraph>
          <Paragraph>
            If any such transfer takes place, Users can find out more by checking the relevant
            sections of this document or inquire with the Owner using the information provided in
            the contact section.
          </Paragraph>
          <HeadingList>Retention time</HeadingList>
          <Paragraph>
            Personal Data shall be processed and stored for as long as required by the purpose they
            have been collected for.
          </Paragraph>
          <Paragraph>
            Therefore:
            <br />
            <ul>
              <li>
                Personal Data collected for purposes related to the performance of a contract
                between the Owner and the User shall be retained until such contract has been fully
                performed.
              </li>
              <li>
                Personal Data collected for the purposes of the Owner’s legitimate interests shall
                be retained as long as needed to fulfill such purposes. Users may find specific
                information regarding the legitimate interests pursued by the Owner within the
                relevant sections of this document or by contacting the Owner.
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            The Owner may be allowed to retain Personal Data for a longer period whenever the User
            has given consent to such processing, as long as such consent is not withdrawn.
            Furthermore, the Owner may be obliged to retain Personal Data for a longer period
            whenever required to do so for the performance of a legal obligation or upon order of an
            authority.
          </Paragraph>
          <Paragraph>
            Once the retention period expires, Personal Data shall be deleted. Therefore, the right
            of access, the right to erasure, the right to rectification and the right to data
            portability cannot be enforced after expiration of the retention period.
          </Paragraph>
          <Heading>The purposes of processing</Heading>
          <Paragraph>
            The Data concerning the User is collected to allow the Owner to provide its Service,
            comply with its legal obligations, respond to enforcement requests, protect its rights
            and interests (or those of its Users or third parties), detect any malicious or
            fraudulent activity, as well as the following: Contacting the User, Displaying content
            from external platforms, Hosting and backend infrastructure, Interaction with external
            social networks and platforms, Managing contacts and sending messages, Social features,
            Registration and authentication, Device permissions for Personal Data access,
            Advertising and Registration and authentication provided directly by this Application.
          </Paragraph>
          <Paragraph>
            For specific information about the Personal Data used for each purpose, the User may
            refer to the section “Detailed information on the processing of Personal Data”.
          </Paragraph>
          <Heading>Device permissions for Personal Data access</Heading>
          <Paragraph>
            Depending on the User&apos;s specific device, this Application may request certain
            permissions that allow it to access the User&apos;s device Data as described below.
          </Paragraph>
          <Paragraph>
            By default, these permissions must be granted by the User before the respective
            information can be accessed. Once the permission has been given, it can be revoked by
            the User at any time. In order to revoke these permissions, Users may refer to the
            device settings or contact the Owner for support at the contact details provided in the
            present document. The exact procedure for controlling app permissions may be dependent
            on the User&apos;s device and software.
          </Paragraph>
          <Paragraph>
            Please note that the revoking of such permissions might impact the proper functioning of
            this Application.
          </Paragraph>
          <Paragraph>
            If User grants any of the permissions listed below, the respective Personal Data may be
            processed (i.e accessed to, modified or removed) by this Application.
          </Paragraph>
          <HeadingList>Phone permission</HeadingList>
          <Paragraph>
            Used for accessing a host of typical features associated with telephony. This enables,
            for instance, read-only access to the “phone state”, which means it enables access to
            the phone number of the device, current mobile network information, or the status of any
            ongoing calls.
          </Paragraph>
          <HeadingList>SMS permission</HeadingList>
          <Paragraph>
            Used for accessing features related to the User&apos;s messaging including the sending,
            receiving and reading of SMS.
          </Paragraph>
          <HeadingList>Storage permission</HeadingList>
          <Paragraph>
            Used for accessing shared external storage, including the reading and adding of any
            items.
          </Paragraph>
          <Heading>Detailed information on the processing of Personal Data</Heading>
          <Paragraph>
            Personal Data is collected for the following purposes and using the following services:
          </Paragraph>
          <br />
          <FaqElement
            faq={{
              question: `Advertising`,
              answer: `  This type of service allows User Data to be utilized for advertising communication
              purposes. These communications are displayed in the form of banners and other
              advertisements on this Application, possibly based on User interests.
              <br />
              This does not mean that all Personal Data are used for this purpose. Information and
              conditions of use are shown below.
              <br />
              Some of the services listed below may use Trackers to identify Users or they may use the
              behavioral retargeting technique, i.e. displaying ads tailored to the User’s interests and
              behavior, including those detected outside this Application. For more information, please
              check the privacy policies of the relevant services.
              <br />
              Services of this kind usually offer the possibility to opt out of such tracking. In
              addition to any opt-out feature offered by any of the services below, Users may learn more
              on how to generally opt out of interest-based advertising within the dedicated section
              "How to opt-out of interest-based advertising" in this document.
              <br />
              <b>Direct marketing via SMS (this Application)</b>
              <br />
              This Application uses the User Data to propose services and products provided by third
              parties or unrelated to the product or service provided by this Application.
              <br />
              Personal Data processed: first name; last name; phone number.
              <br />
              Category of personal information collected according to the CCPA: identifiers.`,
            }}
          />
          <br />
          <FaqElement
            faq={{
              question: `Contacting the User`,
              answer: ` <b>Contact form (This Application)</b>
              <br />
              By filling in the contact form with their Data, the User authorizes this Application to
              use these details to reply to requests for information, quotes or any other kind of
              request as indicated by the form’s header.
              <br />
              Personal Data processed: email address; first name.
              <br />
              Category of personal information collected according to the CCPA: identifiers.
              <br />
              This processing constitutes: <br />
              <ul>
                <li>a sale according to the VCDPA</li>
              </ul>
              <br />
              <b>Mailing List or Newsletter (This Application)</b>
              <br />
              By registering on the mailing list or for the newsletter, the User’s email address will be
              added to the contact list of those who may receive email messages containing information
              of commercial or promotional nature concerning this Application. Your email address might
              also be added to this list as a result of signing up to this Application or after making a
              purchase.
              <br />
              Personal Data processed: email address; first name.
              <br />
              Category of personal information collected according to the CCPA: identifiers.
              <br />
              This processing constitutes:
              <br />
              <ul>
                <li>a sale according to the VCDPA</li>
              </ul>
              <br />
              <b>Phone contact (this Application)</b>
              <br />
              Users that provided their phone number might be contacted for commercial or promotional
              purposes related to this Application, as well as for fulfilling support requests.
              <br />
              Personal Data processed: phone number.
              <br />
              Category of personal information collected according to the CCPA: identifiers.
              <br />
              This processing constitutes: <br />
              <ul>
                <li>a sale according to the CCPA and the VCDPA</li>
              </ul>`,
            }}
          />
          <br />
          <FaqElement
            faq={{
              question: `Device permissions for Personal Data access`,
              answer: `   This Application requests certain permissions from Users that allow it to access the
              User's device Data as described below.
              <br />
              <b>Device permissions for Personal Data access (this Application)</b>
              <br />
              This Application requests certain permissions from Users that allow it to access the
              User's device Data as summarized here and described within this document.
              <br />
              Personal Data processed: Phone permission; SMS permission; Storage permission.
              <br />
              Category of personal information collected according to the CCPA: internet information.`,
            }}
          />
          <br />
          <FaqElement
            faq={{
              question: `Displaying content from external platforms`,
              answer: ` This type of services allows you to view content hosted on external platforms directly
              from the pages of this Application and interact with them. This type of service might
              still collect web traffic data for the pages where the service is installed, even when
              Users do not use it.
              <br />
              <b>Vimeo video (Vimeo, LLC)</b>
              <br />
              Vimeo is a video content visualization service provided by Vimeo, LLC that allows this
              Application to incorporate content of this kind on its pages.
              <br />
              Personal Data processed: Cookies; Usage Data.
              <br />
              Place of processing: US – Privacy Policy.
              <br />
              Category of personal information collected according to the CCPA: internet information.
              <br />
              This processing constitutes:
              <br />
              <ul>
                <li>a sale according to the CCPA and the VCDPA</li>
              </ul>
              <br />
              <b>Instagram widget (Instagram, Inc.)</b>
              <br />
              Instagram is an image visualization service provided by Instagram, Inc. that allows this
              Application to incorporate content of this kind on its pages.
              <br />
              Personal Data processed: Cookies; Usage Data.
              <br />
              Place of processing: US – Privacy Policy. <br />
              Category of personal information collected according to the CCPA: internet information.
              <br />
              This processing constitutes:
              <br />
              <ul>
                <li>a sale according to the CCPA and the VCDPA</li>
              </ul>
              <br />
              <b>Google Programmable Search Engine (Google Inc.)</b>
              <br />
              Google Programmable Search Engine is a search engine embedding service provided by Google
              LLC or by Google Ireland Limited, depending on how the Owner manages the Data processing,
              that allows this Application to incorporate content of this kind on its pages.
              <br />
              Personal Data processed: Cookies; Usage Data.
              <br />
              Place of processing: US – Privacy Policy.
              <br />
              Category of personal information collected according to the CCPA: internet information.
              <br />
              This processing constitutes:
              <br />
              <ul>
                <li>a sale according to the CCPA and the VCDPA</li>
              </ul>`,
            }}
          />
          <br />
          <FaqElement
            faq={{
              question: `Hosting and backend infrastructure`,
              answer: `          This type of services has the purpose of hosting data and files that enable this
              Application to run and be distributed as well as to provide a ready-made infrastructure to
              run specific features or parts of this Application. Some of these services work through
              geographically distributed servers, making it difficult to determine the actual location
              where the Personal Data are stored.
              <br />
              <b>Amazon Web Services (AWS) (Amazon)</b>
              <br />
              Amazon Web Services (AWS) is a hosting and backend service provided by Amazon Web
              Services, Inc.
              <br />
              Personal Data processed: various types of Data as specified in the privacy policy of the
              service.
              <br />
              Place of processing: See the Amazon privacy policy – Privacy Policy.
              <br />
              Category of personal information collected according to the CCPA: internet information.`,
            }}
          />
          <br />
          <FaqElement
            faq={{
              question: `Interaction with external social networks and platforms`,
              answer: `  This type of service allows interaction with social networks or other external platforms
              directly from the pages of this Application.
              <br />
              The interaction and information obtained through this Application are always subject to
              the User’s privacy settings for each social network.
              <br />
              This type of service might still collect traffic data for the pages where the service is
              installed, even when Users do not use it.
              <br />
              It is recommended to log out from the respective services in order to make sure that the
              processed data on this Application isn’t being connected back to the User’s profile.
              <br />
              <b>Facebook Like button and social widgets (Facebook, Inc.)</b>
              <br />
              The Facebook Like button and social widgets are services allowing interaction with the
              Facebook social network provided by Facebook, Inc.
              <br />
              Personal Data processed: Cookies; Usage Data.
              <br />
              Place of processing: US – Privacy Policy.
              <br />
              Category of personal information collected according to the CCPA: internet information.
              <br />
              This processing constitutes:
              <br />
              <ul>
                <li>a sale according to the CCPA and the VCDPA</li>
              </ul>`,
            }}
          />
          <br />
          <FaqElement
            faq={{
              question: `Managing contacts and sending messages`,
              answer: `     This type of services makes it possible to manage a database of email contacts, phone
              contacts or any other contact information to communicate with the User. These services may
              also collect data concerning the date and time when the message was viewed by the User, as
              well as when the User interacted with it, such as by clicking on links included in the
              message.
              <br />
              <b>Mailchimp (The Rocket Science Group, LLC.)</b>
              <br />
              Mailchimp is an email address management and message sending service provided by Intuit
              Inc. Personal Data processed: email address. Place of processing: US – Privacy Policy.
              Category of personal information collected according to the CCPA: identifiers. This
              processing constitutes: a sale according to the CCPA and the VCDPA a sharing according to
              the CCPA targeted advertising according to the VCDPA
              <br />
              <b>ActiveCampaign Text Message and SMS Marketing (ActiveCampaign, Inc.)</b>
              <br />
              ActiveCampaign Text Message and SMS Marketing is an email address management and message
              sending service provided by ActiveCampaign, Inc. Personal Data processed: phone number.
              Place of processing: United States – Privacy Policy. Category of personal information
              collected according to the CCPA: identifiers.
              <br />
              <b>Iterable SMS marketing (Iterable, Inc.)</b>
              <br />
              Iterable SMS marketing is a phone numbers management and communication service provided by
              Iterable, Inc. Personal Data processed: first name; last name; phone number. Place of
              processing: United States – Privacy Policy. Category of personal information collected
              according to the CCPA: identifiers. This processing constitutes: a sharing according to
              the CCPA
              <br />
              <b>Customer.io (Peaberry Software Inc.)</b>
              <br />
              Customer.io is an email address management and message sending service provided by
              Peaberry Software Inc. Personal Data processed: email address; Trackers; Usage Data. Place
              of processing: United States – Privacy Policy. Category of personal information collected
              according to the CCPA: identifiers; internet information. This processing constitutes: a
              sale according to the CCPA and the VCDPA a sharing according to the CCPA targeted
              advertising according to the VCDPA`,
            }}
          />
          <br />
          <FaqElement
            faq={{
              question: `Registration and authentication`,
              answer: `          By registering or authenticating, Users allow this Application to identify them and give
              them access to dedicated services. Depending on what is described below, third parties may
              provide registration and authentication services. In this case, this Application will be
              able to access some Data, stored by these third-party services, for registration or
              identification purposes. Some of the services listed below may also collect Personal Data
              for targeting and profiling purposes; to find out more, please refer to the description of
              each service.
              <br />
              <b>Google OAuth (Google LLC)</b>
              <br />
              Google OAuth is a registration and authentication service provided by Google LLC and is
              connected to the Google network. Personal Data processed: various types of Data as
              specified in the privacy policy of the service. Place of processing: United States –
              Privacy Policy. Category of personal information collected according to the CCPA: internet
              information. This processing constitutes: a sale according to the CCPA and the VCDPA`,
            }}
          />
          <br />
          <FaqElement
            faq={{
              question: `Registration and authentication provided directly by this Application`,
              answer: `  By registering or authenticating, Users allow this Application to identify them and give
              them access to dedicated services. The Personal Data is collected and stored for
              registration or identification purposes only. The Data collected are only those necessary
              for the provision of the service requested by the Users.
              <br />
              <b>Direct registration (this Application)</b>
              <br />
              The User registers by filling out the registration form and providing the Personal Data
              directly to this Application. Personal Data processed: date of birth; email address; first
              name; last name. Category of personal information collected according to the CCPA:
              identifiers.`,
            }}
          />
          <br />
          <FaqElement
            faq={{
              question: `Social features`,
              answer: `          <b>Inviting and suggesting friends (This Application)</b>
              <br />
              This Application may use the Personal Data provided to allow Users to invite their friends
              - for example through the address book, if access has been provided - and to suggest
              friends or connections inside it. Personal Data processed: various types of Data. Category
              of personal information collected according to the CCPA: internet information. This
              processing constitutes: a sale according to the VCDPA
              <br />
              <b>Public profile (This Application)</b>
              <br />
              Users may have public profiles that other Users can display. In addition to the Personal
              Data provided, this profile may contain Users' interactions with this Application.
              Personal Data processed: city; country; email address; first name. Category of personal
              information collected according to the CCPA: identifiers. This processing constitutes: a
              sale according to the VCDPA`,
            }}
          />

          <Heading>Information on opting out of interest-based advertising</Heading>
          <Paragraph>
            In addition to any opt-out feature provided by any of the services listed in this
            document, Users may learn more on how to generally opt out of interest-based advertising
            within the dedicated section of the Cookie Policy.
          </Paragraph>
          <Heading>Further information about the processing of Personal Data</Heading>
          <br />
          <FaqElement
            faq={{
              question: `Unique device identification`,
              answer: `This Application may track Users by storing a unique identifier of their device, for analytics purposes or for storing Users' preferences.`,
            }}
          />

          <Heading>The rights of Users</Heading>
          <Paragraph>
            Users may exercise certain rights regarding their Data processed by the Owner. In
            particular, Users have the right to do the following:
            <br />
            <ul>
              <li>
                <b>Withdraw their consent at any time</b>. Users have the right to withdraw consent
                where they have previously given their consent to the processing of their Personal
                Data.
              </li>
              <li>
                <b>Object to processing of their Data</b>. Users have the right to object to the
                processing of their Data if the processing is carried out on a legal basis other
                than consent. Further details are provided in the dedicated section below.
              </li>
              <li>
                <b>Access their Data</b>. Users have the right to learn if Data is being processed
                by the Owner, obtain disclosure regarding certain aspects of the processing and
                obtain a copy of the Data undergoing processing.
              </li>
              <li>
                <b>Verify and seek rectification</b>. Users have the right to verify the accuracy of
                their Data and ask for it to be updated or corrected.
              </li>
              <li>
                <b>Restrict the processing of their Data</b>. Users have the right, under certain
                circumstances, to restrict the processing of their Data. In this case, the Owner
                will not process their Data for any purpose other than storing it.
              </li>
              <li>
                <b>Have their Personal Data deleted or otherwise removed</b>. Users have the right,
                under certain circumstances, to obtain the erasure of their Data from the Owner.
              </li>
              <li>
                <b>Receive their Data and have it transferred to another controller</b>. Users have
                the right to receive their Data in a structured, commonly used and machine readable
                format and, if technically feasible, to have it transmitted to another controller
                without any hindrance. This provision is applicable provided that the Data is
                processed by automated means and that the processing is based on the User&apos;s
                consent, on a contract which the User is part of or on pre-contractual obligations
                thereof.
              </li>
              <li>
                <b>Lodge a complaint</b>. Users have the right to bring a claim before their
                competent data protection authority.
              </li>
            </ul>
          </Paragraph>
          <HeadingList>Details about the right to object to processing</HeadingList>
          <Paragraph>
            Where Personal Data is processed for a public interest, in the exercise of an official
            authority vested in the Owner or for the purposes of the legitimate interests pursued by
            the Owner, Users may object to such processing by providing a ground related to their
            particular situation to justify the objection.
          </Paragraph>
          <Paragraph>
            Users must know that, however, should their Personal Data be processed for direct
            marketing purposes, they can object to that processing at any time without providing any
            justification. To learn, whether the Owner is processing Personal Data for direct
            marketing purposes, Users may refer to the relevant sections of this document.
          </Paragraph>
          <HeadingList>How to exercise these rights</HeadingList>
          <Paragraph>
            Any requests to exercise User rights can be directed to the Owner through the contact
            details provided in this document. These requests can be exercised free of charge and
            will be addressed by the Owner as early as possible and always within one month.
          </Paragraph>
          <Heading>Cookie Policy</Heading>
          <Paragraph>
            This Application uses Trackers. To learn more, the User may consult the Cookie Policy.
          </Paragraph>
          <Heading>Additional information about Data collection and processing</Heading>
          <HeadingList>Legal action</HeadingList>
          <Paragraph>
            The User&apos;s Personal Data may be used for legal purposes by the Owner in Court or in
            the stages leading to possible legal action arising from improper use of this
            Application or the related Services. The User declares to be aware that the Owner may be
            required to reveal personal data upon request of public authorities.
          </Paragraph>
          <HeadingList>Additional information about User&apos;s Personal Data</HeadingList>
          <Paragraph>
            In addition to the information contained in this privacy policy, this Application may
            provide the User with additional and contextual information concerning particular
            Services or the collection and processing of Personal Data upon request.
          </Paragraph>
          <HeadingList>System logs and maintenance</HeadingList>
          <Paragraph>
            For operation and maintenance purposes, this Application and any third-party services
            may collect files that record interaction with this Application (System logs) or use
            other Personal Data (such as the IP Address) for this purpose.
          </Paragraph>
          <HeadingList>Information not contained in this policy</HeadingList>
          <Paragraph>
            More details concerning the collection or processing of Personal Data may be requested
            from the Owner at any time. Please see the contact information at the beginning of this
            document.
          </Paragraph>
          <HeadingList>How “Do Not Track” requests are handled</HeadingList>
          <Paragraph>
            This Application does not support “Do Not Track” requests. To determine whether any of
            the third-party services it uses honor the “Do Not Track” requests, please read their
            privacy policies.
          </Paragraph>
          <HeadingList>Changes to this privacy policy</HeadingList>
          <Paragraph>
            The Owner reserves the right to make changes to this privacy policy at any time by
            notifying its Users on this page and possibly within this Application and/or - as far as
            technically and legally feasible - sending a notice to Users via any contact information
            available to the Owner. It is strongly recommended to check this page often, referring
            to the date of the last modification listed at the bottom.
          </Paragraph>
          <Paragraph>
            Should the changes affect processing activities performed on the basis of the
            User&apos;s consent, the Owner shall collect new consent from the User, where required.
          </Paragraph>
          <Heading>Information for Californian consumers</Heading>
          <Paragraph>
            This part of the document integrates with and supplements the information contained in
            the rest of the privacy policy and is provided by the business running this Application
            and, if the case may be, its parent, subsidiaries and affiliates (for the purposes of
            this section referred to collectively as “we”, “us”, “our”).
          </Paragraph>
          <Paragraph>
            The provisions contained in this section apply to all Users (Users are referred to
            below, simply as “you”, “your”, “yours”), who are consumers residing in the state of
            California, United States of America, according to the &quot;California Consumer Privacy
            Act of 2018&quot; (the &quot;CCPA&quot;), as updated by the &quot;California Privacy
            Rights Act&quot; (the &quot;CPRA&quot;) and subsequent regulations. For such consumers,
            these provisions supersede any other possibly divergent or conflicting provisions
            contained in the privacy policy.
          </Paragraph>
          <Paragraph>
            This part of the document uses the term “personal information“ as defined in the
            California Consumer Privacy Act (CCPA/CPRA).
          </Paragraph>
          <HeadingList>Notice at collection</HeadingList>
          <Paragraph>
            <b>Categories of personal information collected, used, sold, or shared</b>
          </Paragraph>
          <Paragraph>
            In this section we summarize the categories of personal information that we&apos;ve
            collected, used, sold, or shared and the purposes thereof.{' '}
            <b>
              You can read about these activities in detail in the section titled “Detailed
              information on the processing of Personal Data” within this document
            </b>
            .
          </Paragraph>
          <Paragraph>
            <b>Information we collect: the categories of personal information we collect</b>
          </Paragraph>
          <Paragraph>
            We have collected the following categories of personal information about you:
            identifiers and internet information.
          </Paragraph>
          <Paragraph>We do not collect sensitive personal information.</Paragraph>
          <Paragraph>
            We will not collect additional categories of personal information without notifying you.
          </Paragraph>
          <Paragraph>
            <b>What are the purposes for which we use your personal information?</b>
          </Paragraph>
          <Paragraph>
            We may use your personal information to allow the operational functioning of this
            Application and features thereof (“business purposes”). In such cases, your personal
            information will be processed in a fashion necessary and proportionate to the business
            purpose for which it was collected, and strictly within the limits of compatible
            operational purposes.
          </Paragraph>
          <Paragraph>
            We may also use your personal information for other reasons such as for commercial
            purposes (as indicated within the section “Detailed information on the processing of
            Personal Data” within this document), as well as for complying with the law and
            defending our rights before the competent authorities where our rights and interests are
            threatened or we suffer an actual damage.
          </Paragraph>
          <Paragraph>
            We won’t process your information for unexpected purposes, or for purposes incompatible
            with the purposes originally disclosed, without your consent.
          </Paragraph>
          <Paragraph>
            <b>How long do we keep your personal information?</b>
          </Paragraph>
          <Paragraph>
            Unless stated otherwise inside the “Detailed information on the processing of Personal
            Data” section, we will not retain your personal information for longer than is
            reasonably necessary for the purpose(s) they have been collected for.
          </Paragraph>
          <Paragraph>
            <b>
              How we collect information: what are the sources of the personal information we
              collect?
            </b>
          </Paragraph>
          <Paragraph>
            We collect the above-mentioned categories of personal information, either directly or
            indirectly, from you when you use this Application.
          </Paragraph>
          <Paragraph>
            For example, you directly provide your personal information when you submit requests via
            any forms on this Application. You also provide personal information indirectly when you
            navigate this Application, as personal information about you is automatically observed
            and collected.
          </Paragraph>
          <Paragraph>
            Finally, we may collect your personal information from third parties that work with us
            in connection with the Service or with the functioning of this Application and features
            thereof.
          </Paragraph>
          <Paragraph>
            <b>
              How we use the information we collect: disclosing of your personal information with
              third parties for a business purpose
            </b>
          </Paragraph>
          <Paragraph>
            For our purposes, the word “third party” means a person who is not any of the following:
            a service provider or a contractor, as defined by the CCPA.
          </Paragraph>
          <Paragraph>
            We disclose your personal information with the third parties{' '}
            <b>
              listed in detail in the section titled “Detailed information on the processing of
              Personal Data” within this document
            </b>
            . These third parties are grouped and categorized in accordance with the different
            purposes of processing.
          </Paragraph>
          <Paragraph>
            <b>Sale or sharing of your personal information</b>
          </Paragraph>
          <Paragraph>
            For our purposes, the word “sale” means any “selling, renting, releasing, disclosing,
            disseminating, making available, transferring or otherwise communicating orally, in
            writing, or by electronic means, a consumer&apos;s personal information by the business
            to <b>a third party, for monetary or other valuable consideration”</b>, as defined by
            the CCPA.
          </Paragraph>
          <Paragraph>
            This means that, for example, a sale can happen whenever an application runs ads, or
            makes statistical analyses on the traffic or views, or simply because it uses tools such
            as social network plugins and the like.
          </Paragraph>
          <Paragraph>
            For our purposes, the word “sharing” means any “sharing, renting, releasing, disclosing,
            disseminating, making available, transferring, or otherwise communicating orally, in
            writing, or by electronic or other means, a consumer&apos;s personal information by the
            business to a third party for cross-context behavioral advertising, whether or not for
            monetary or other valuable consideration, including transactions between a business and
            a third party for cross-context behavioral advertising for the benefit of a business in
            which no money is exchanged”, as defined by the CCPA. Please note that the exchange of
            personal information with a service provider pursuant to a written contract that meets
            the requirements set by the CCPA, does not constitute a sale or sharing of your personal
            information.
          </Paragraph>
          <HeadingList>
            Your right to opt out of the sale or sharing of your personal information and how you
            can exercise it
          </HeadingList>
          <Paragraph>
            We sell or share your personal information with the third parties{' '}
            <b>
              listed in detail in the section titled “Detailed information on the processing of
              Personal Data” within this document
            </b>
            . These third parties are grouped and categorized in accordance with the different
            purposes of processing.
          </Paragraph>
          <Paragraph>
            You have the right to opt out of the sale or sharing of your personal information. This
            means that whenever you request us to stop selling or sharing your personal information,
            we will abide by your request. Such requests can be made freely, at any time, without
            submitting any verifiable request. To fully exercise your right to opt out, you can
            contact us at any time using the contact details provided in this document. For a
            simplified opt-out method you can also use the privacy choices link provided on this
            Application.
          </Paragraph>
          <Paragraph>
            If you want to submit requests to opt out of the sale or sharing of personal information
            via a user-enabled global privacy control, like the Global Privacy Control (“GPC”), you
            are free to do so and we will abide by such request. The GPC consists of a setting or
            extension in the browser or mobile device and acts as a mechanism that websites can use
            to indicate they support the GPC signal. If you want to use GPC, you can download and
            enable it via a participating browser or browser extension. More information about
            downloading GPC is available here.
          </Paragraph>
          <Paragraph>
            We use any personal information collected from you in connection with the submission of
            your opt-out request solely for the purposes of complying with the opt-out request.
          </Paragraph>
          <Paragraph>
            Once you have opted out, we are required to wait at least 12 months before asking
            whether you have changed your mind.
          </Paragraph>
          <HeadingList>
            Your privacy rights under the California Consumer Privacy Act and how to exercise them
          </HeadingList>
          <Paragraph>
            <b>The right to access personal information: the right to know and to portability</b>
          </Paragraph>
          <Paragraph>
            You have the right to request that we disclose to you:
            <br />
            <ul>
              <li>the categories of personal information that we collect about you;</li>
              <li>the sources from which the personal information is collected;</li>
              <li>the purposes for which we use your information;</li>
              <li>to whom we disclose such information;</li>
              <li>the specific pieces of personal information we have collected about you.</li>
            </ul>
          </Paragraph>
          <Paragraph>
            You also have the right to know what personal information is sold or shared and to whom.
            In particular, you have the right to request two separate lists from us where we
            disclose:
            <br />
            <ul>
              <li>
                the categories of personal information that we sold or shared about you and the
                categories of third parties to whom the personal information was sold or shared;
              </li>
              <li>
                the categories of personal information that we disclosed about you for a business
                purpose and the categories of persons to whom it was disclosed for a business
                purpose.
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            The disclosure described above will be limited to the personal information collected or
            used over the past 12 months.
          </Paragraph>
          <Paragraph>
            If we deliver our response electronically, the information enclosed will be
            &quot;portable&quot;, i.e. delivered in an easily usable format to enable you to
            transmit the information to another entity without hindrance - provided that this is
            technically feasible.
          </Paragraph>
          <Paragraph>
            <b>The right to request the deletion of your personal information</b>
          </Paragraph>
          <Paragraph>
            You have the right to request that we delete any of your personal information, subject
            to exceptions set forth by the law (such as, including but not limited to, where the
            information is used to identify and repair errors on this Application, to detect
            security incidents and protect against fraudulent or illegal activities, to exercise
            certain rights etc.).
          </Paragraph>
          <Paragraph>
            If no legal exception applies, as a result of exercising your right, we will delete your
            personal information and notify any of our service providers and all third parties to
            whom we have sold or shared the personal information to do so – provided that this is
            technically feasible and doesn’t involve disproportionate effort.
          </Paragraph>
          <Paragraph>
            <b>The right to correct inaccurate personal information</b>
          </Paragraph>
          <Paragraph>
            You have the right to request that we correct any inaccurate personal information we
            maintain about you, taking into account the nature of the personal information and the
            purposes of the processing of the personal information.
          </Paragraph>
          <Paragraph>
            <b>
              The right to opt out of sale or sharing of personal information and to limit the use
              of your sensitive personal information
            </b>
          </Paragraph>
          <Paragraph>
            You have the right to opt out of the sale or sharing of your personal information. You
            also have the right to request that we limit our use or disclosure of your sensitive
            personal information.
          </Paragraph>
          <Paragraph>
            <b>
              The right of no retaliation following opt-out or exercise of other rights (the right
              to non-discrimination)
            </b>
          </Paragraph>
          <Paragraph>
            We will not discriminate against you for exercising your rights under the CCPA. This
            means that we will not discriminate against you, including, but not limited to, by
            denying goods or services, charging you a different price, or providing a different
            level or quality of goods or services just because you exercised your consumer privacy
            rights.
          </Paragraph>
          <Paragraph>
            However, if you refuse to provide your personal information to us or ask us to delete or
            stop selling your personal information, and that personal information or sale is
            necessary for us to provide you with goods or services, we may not be able to complete
            that transaction.
          </Paragraph>
          <Paragraph>
            To the extent permitted by the law, we may offer you promotions, discounts, and other
            deals in exchange for collecting, keeping, or selling your personal information,
            provided that the financial incentive offered is reasonably related to the value of your
            personal information.
          </Paragraph>
          <Paragraph>
            <b>How to exercise your rights</b>
          </Paragraph>
          <Paragraph>
            To exercise the rights described above, you need to submit your verifiable request to us
            by contacting us via the details provided in this document.
          </Paragraph>
          <Paragraph>
            For us to respond to your request, it’s necessary that we know who you are. Therefore,
            you can only exercise the above rights by making a verifiable request which must:
            <br />
            <ul>
              <li>
                provide sufficient information that allows us to reasonably verify you are the
                person about whom we collected personal information or an authorized representative;
              </li>
              <li>
                describe your request with sufficient detail that allows us to properly understand,
                evaluate, and respond to it.
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            We will not respond to any request if we are unable to verify your identity and
            therefore confirm the personal information in our possession actually relates to you.
          </Paragraph>
          <Paragraph>
            Making a verifiable consumer request does not require you to create an account with us.
            We will use any personal information collected from you in connection with the
            verification of your request solely for the purposes of verification and shall not
            further disclose the personal information, retain it longer than necessary for purposes
            of verification, or use it for unrelated purposes.
          </Paragraph>
          <Paragraph>
            If you cannot personally submit a verifiable request, you can authorize a person
            registered with the California Secretary of State to act on your behalf.
          </Paragraph>
          <Paragraph>
            If you are an adult, you can make a verifiable request on behalf of a minor under your
            parental authority.
          </Paragraph>
          <Paragraph>
            You can submit a maximum number of 2 requests over a period of 12 months.
          </Paragraph>
          <Paragraph>
            <b>How and when we are expected to handle your request</b>
          </Paragraph>
          <Paragraph>
            We will confirm receipt of your verifiable request within 10 days and provide
            information about how we will process your request.
          </Paragraph>
          <Paragraph>
            We will respond to your request within 45 days of its receipt. Should we need more time,
            we will explain to you the reasons why, and how much more time we need. In this regard,
            please note that we may take up to 90 days to fulfill your request.
          </Paragraph>
          <Paragraph>
            Our disclosure(s) will cover the preceding 12-month period. Only with regard to personal
            information collected on or after January 1, 2022, you have the right to request that we
            disclose information beyond the 12-month period, and we will provide them to you unless
            doing so proves impossible or would involve a disproportionate effort.
          </Paragraph>
          <Paragraph>
            Should we deny your request, we will explain you the reasons behind our denial.
          </Paragraph>
          <Paragraph>
            We do not charge a fee to process or respond to your verifiable request unless such
            request is manifestly unfounded or excessive. In such cases, we may charge a reasonable
            fee, or refuse to act on the request. In either case, we will communicate our choices
            and explain the reasons behind it.
          </Paragraph>
          <Heading>Information for Virginia consumers</Heading>
          <Paragraph>
            This part of the document integrates with and supplements the information contained in
            the rest of the privacy policy and is provided by the controller running this
            Application and, if the case may be, its parent, subsidiaries and affiliates (for the
            purposes of this section referred to collectively as “we”, “us”, “our”).
          </Paragraph>
          <Paragraph>
            The provisions contained in this section apply to all Users (Users are referred to
            below, simply as “you”, “your”, “yours”), who are consumers residing in the Commonwealth
            of Virginia, according to the “Virginia Consumer Data Protection Act&quot; (the
            &quot;VCDPA&quot;), and, for such consumers, these provisions supersede any other
            possibly divergent or conflicting provisions contained in the privacy policy.
          </Paragraph>
          <Paragraph>
            This part of the document uses the term “personal data” as defined in the VCDPA.
          </Paragraph>
          <HeadingList>Categories of personal data processed</HeadingList>
          <Paragraph>
            In this section, we summarize the categories of personal data that we&apos;ve processed
            and the purposes thereof.{' '}
            <b>
              You can read about these activities in detail in the section titled “Detailed
              information on the processing of Persona Data” within this document
            </b>
            .
          </Paragraph>
          <Paragraph>
            <b>Categories of personal data we collect</b>
          </Paragraph>
          <Paragraph>
            We have collected the following categories of personal data: identifiers and internet
            information
          </Paragraph>
          <Paragraph>We do not collect sensitive data.</Paragraph>
          <Paragraph>
            We will not collect additional categories of personal data without notifying you.
          </Paragraph>
          <Paragraph>
            <b>Why we process your personal data</b>
          </Paragraph>
          <Paragraph>
            To find out why we process your personal data, you can read the sections titled
            “Detailed information on the processing of Personal Data” and “The purposes of
            processing” within this document.
          </Paragraph>
          <Paragraph>
            We won’t process your information for unexpected purposes, or for purposes incompatible
            with the purposes originally disclosed, without your consent. You can freely give, deny,
            or withdraw such consent at any time using the contact details provided in this
            document.
          </Paragraph>
          <Paragraph>
            <b>How we use the data we collect: sharing of your personal data with third parties</b>
          </Paragraph>
          <Paragraph>
            We share your personal data with the third parties{' '}
            <b>
              listed in detail in the section titled “Detailed information on the processing of
              Personal Data” within this document
            </b>
            . These third parties are grouped and categorized in accordance with the different
            purposes of processing. For our purposes, the word &quot;third party&quot; means &quot;a
            natural or legal person, public authority, agency, or body other than the consumer,
            controller, processor, or an affiliate of the processor or the controller&quot; as
            defined by the VCDPA.
          </Paragraph>
          <Paragraph>
            <b>Sale of your personal data</b>
          </Paragraph>
          <Paragraph>
            For our purposes, the word “sale” means any “exchange of personal data for monetary
            consideration by us to a third party“ as defined by the VCDPA. Please note that
            according to the VCDPA, the disclosure of personal data to a processor that processes
            personal data on behalf of a controller does not constitute a sale. As specified in the
            “Detailed information on the processing of Personal Data” section of this document, our
            use of your personal information may be considered a sale under VCDPA.
          </Paragraph>
          <Paragraph>
            <b>
              Your right to opt out of the sale of your personal data and how you can exercise it
            </b>
          </Paragraph>
          <Paragraph>
            You have the right to opt out of the sale of your personal data. This means that
            whenever you request us to stop selling your data, we will abide by your request. To
            fully exercise your right to opt out you can contact us at any time using the contact
            details provided in this document.
          </Paragraph>
          <Paragraph>
            We use any personal data collected from you in connection with the submission of your
            opt-out request solely for the purpose of complying with the request.
          </Paragraph>
          <Paragraph>
            <b>Processing of your personal data for targeted advertising</b>
          </Paragraph>
          <Paragraph>
            For our purposes, the word &quot;targeted advertising&quot; means &quot;displaying
            advertisements to you where the advertisement is selected based on personal data
            obtained from your activities over time and across nonaffiliated websites or online
            applications to predict your preferences or interests&quot; as defined by the VCDPA.
          </Paragraph>
          <Paragraph>
            To find out more details on the processing of your personal data for targeted
            advertising purposes, you can read the section titled “Detailed information on the
            processing of Personal Data” within this document.
          </Paragraph>
          <Paragraph>
            <b>
              Your right to opt out of the processing of your personal data for targeted advertising
              and how you can exercise it
            </b>
          </Paragraph>
          <Paragraph>
            You have the right to opt out of the processing of your personal data for targeted
            advertising. This means that whenever you ask us to stop processing your data for
            targeted advertising, we will abide by your request. To fully exercise your right to opt
            out you can contact us at any time, using the contact details provided in this document.
          </Paragraph>
          <Paragraph>
            We use any personal data collected from you in connection with the submission of your
            opt-out request solely for the purposes of complying with the opt-out request.
          </Paragraph>
          <HeadingList>
            Your privacy rights under the Virginia Consumer Data Protection Act and how to exercise
            them
          </HeadingList>
          <Paragraph>
            You may exercise certain rights regarding your data processed by us. In particular, you
            have the right to do the following:
            <br />
            <ul>
              <li>
                <b>access personal data: the right to know</b>. You have the right to request that
                we confirm whether or not we are processing your personal data. You also have the
                right to access such personal data.
              </li>
              <li>
                <b>correct inaccurate personal data</b>. You have the right to request that we
                correct any inaccurate personal data we maintain about you, taking into account the
                nature of the personal data and the purposes of the processing of the personal data.
              </li>
              <li>
                <b>request the deletion of your personal data</b>. You have the right to request
                that we delete any of your personal data.
              </li>
              <li>
                <b>obtain a copy of your personal data</b>. We will provide your personal data in a
                portable and usable format that allows you to transfer data easily to another entity
                – provided that this is technically feasible.
              </li>
              <li>
                <b>
                  opt out of the processing of your personal data for the purposes of targeted
                  advertising
                </b>
                , the <b>sale of personal data</b>, or <b>profiling</b> in furtherance of decisions
                that produce legal or similarly significant effects concerning you.
              </li>
              <li>
                <b>non-discrimination</b>. We will not discriminate against you for exercising your
                rights under the VCDPA. This means that we will not, among other things, deny goods
                or services, charge you a different price, or provide a different level or quality
                of goods or services just because you exercised your consumer privacy rights.
                However, if you refuse to provide your personal data to us or ask us to delete or
                stop selling your personal data, and that personal data or sale is necessary for us
                to provide you with goods or services, we may not be able to complete that
                transaction. To the extent permitted by the law, we may offer a different price,
                rate, level, quality, or selection of goods or services to you, including offering
                goods or services for no fee, if you have exercised your right to opt out, or our
                offer is related to your voluntary participation in a bona fide loyalty, rewards,
                premium features, discounts, or club card program.
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            <b>How to exercise your rights</b>
          </Paragraph>
          <Paragraph>
            To exercise the rights described above, you need to submit your request to us by
            contacting us via the contact details provided in this document.
          </Paragraph>
          <Paragraph>For us to respond to your request, we need to know who you are.</Paragraph>
          <Paragraph>
            We will not respond to any request if we are unable to verify your identity using
            commercially reasonable efforts and therefore confirm that the personal data in our
            possession actually relates to you. In such cases, we may request that you provide
            additional information which is reasonably necessary to authenticate you and your
            request.
          </Paragraph>
          <Paragraph>
            Making a consumer request does not require you to create an account with us. However, we
            may require you to use your existing account. We will use any personal data collected
            from you in connection with your request solely for the purposes of authentication,
            without further disclosing the personal data, retaining it longer than necessary for
            purposes of authentication, or using it for unrelated purposes.
          </Paragraph>
          <Paragraph>
            If you are an adult, you can make a request on behalf of a minor under your parental
            authority.
          </Paragraph>
          <Paragraph>
            <b>How and when we are expected to handle your request</b>
          </Paragraph>
          <Paragraph>
            We will respond to your request without undue delay, but in all cases and at the latest
            within 45 days of its receipt. Should we need more time, we will explain to you the
            reasons why, and how much more time we need. In this regard, please note that we may
            take up to 90 days to fulfill your request.
          </Paragraph>
          <Paragraph>
            Should we deny your request, we will explain to you the reasons behind our denial
            without undue delay, but in all cases and at the latest within 45 days of receipt of the
            request. It is your right to appeal such decision by submitting a request to us via the
            details provided in this document. Within 60 days of receipt of the appeal, we will
            inform you in writing of any action taken or not taken in response to the appeal,
            including a written explanation of the reasons for the decisions. If the appeal is
            denied you may contact the Attorney General to submit a complaint.
          </Paragraph>
          <Paragraph>
            We do not charge a fee to respond to your request, for up to two requests per year. If
            your request is manifestly unfounded, excessive or repetitive, we may charge a
            reasonable fee or refuse to act on the request. In either case, we will communicate our
            choices and explain the reasons behind them.
          </Paragraph>
          <Heading>Definitions and legal references</Heading>
          <Paragraph>
            <b>Personal Data (or Data)</b>
          </Paragraph>
          <Paragraph>
            Any information that directly, indirectly, or in connection with other information —
            including a personal identification number — allows for the identification or
            identifiability of a natural person.
          </Paragraph>
          <Paragraph>
            <b>Usage Data</b>
          </Paragraph>
          <Paragraph>
            Information collected automatically through this Application (or third-party services
            employed in this Application), which can include: the IP addresses or domain names of
            the computers utilized by the Users who use this Application, the URI addresses (Uniform
            Resource Identifier), the time of the request, the method utilized to submit the request
            to the server, the size of the file received in response, the numerical code indicating
            the status of the server&apos;s answer (successful outcome, error, etc.), the country of
            origin, the features of the browser and the operating system utilized by the User, the
            various time details per visit (e.g., the time spent on each page within the
            Application) and the details about the path followed within the Application with special
            reference to the sequence of pages visited, and other parameters about the device
            operating system and/or the User&apos;s IT environment.
          </Paragraph>
          <Paragraph>
            <b>User</b>
          </Paragraph>
          <Paragraph>
            The individual using this Application who, unless otherwise specified, coincides with
            the Data Subject.
          </Paragraph>
          <Paragraph>
            <b>Data Subject</b>
          </Paragraph>
          <Paragraph>The natural person to whom the Personal Data refers.</Paragraph>
          <Paragraph>
            <b>Data Processor (or Data Supervisor)</b>
          </Paragraph>
          <Paragraph>
            The natural or legal person, public authority, agency or other body which processes
            Personal Data on behalf of the Controller, as described in this privacy policy.
          </Paragraph>
          <Paragraph>
            <b>Data Controller (or Owner)</b>
          </Paragraph>
          <Paragraph>
            The natural or legal person, public authority, agency or other body which, alone or
            jointly with others, determines the purposes and means of the processing of Personal
            Data, including the security measures concerning the operation and use of this
            Application. The Data Controller, unless otherwise specified, is the Owner of this
            Application.
          </Paragraph>
          <Paragraph>
            <b>This Application</b>
          </Paragraph>
          <Paragraph>
            The means by which the Personal Data of the User is collected and processed.
          </Paragraph>
          <Paragraph>
            <b>Service</b>
          </Paragraph>
          <Paragraph>
            The service provided by this Application as described in the relative terms (if
            available) and on this site/application.
          </Paragraph>
          <Paragraph>
            <b>European Union (or EU)</b>
          </Paragraph>
          <Paragraph>
            Unless otherwise specified, all references made within this document to the European
            Union include all current member states to the European Union and the European Economic
            Area.
          </Paragraph>
          <Paragraph>
            <b>Cookie</b>
          </Paragraph>
          <Paragraph>
            Cookies are Trackers consisting of small sets of data stored in the User&apos;s browser.
          </Paragraph>
          <Paragraph>
            <b>Tracker</b>
          </Paragraph>
          <Paragraph>
            Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons,
            embedded scripts, e-tags and fingerprinting - that enables the tracking of Users, for
            example by accessing or storing information on the User&apos;s device.
          </Paragraph>
          <br />
          <Paragraph>
            <b>Legal information</b>
          </Paragraph>
          <Paragraph>
            This privacy statement has been prepared based on provisions of multiple legislations,
            including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).
          </Paragraph>
          <Paragraph>
            This privacy policy relates solely to this Application, if not stated otherwise within
            this document.
          </Paragraph>
        </Container>
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default Privacy;
